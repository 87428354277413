import { EMPTYVALUE } from 'components/common/constants'
import { store } from 'redux/store'
import { addNotification } from 'redux/notificationsSlice'

// export const getCountryByType = (type) => {
//   if (!type) return
//   if (type === 'ALL') return

//   return require(`images/icons/countries/${type}.svg`)
// }

export const errorNotify = (data) => {
  if (data?.status === 'error') {
    const errorList = data?.error
    for (let key in errorList) {
      store.dispatch(
        addNotification({
          type: 'error',
          text: `${key} - ${errorList[key]}`,
        }),
      )
    }
  }
}

export const notify = (text, type = 'error') => {
  store.dispatch(
    addNotification({
      type,
      text,
    }),
  )
}

export const handleCoppy = (info) => {
  if (info) {
    navigator.clipboard
      .writeText(info)
      .then(() => notify('Coppied!', 'success'))
      .catch((err) => notify(err))
  }
}

export const stripHTMLTags = (inputText) => {
  if (typeof inputText !== 'string') return ''

  const htmlTagsRegex = /<[^>]*>/g
  const htmlEntitiesRegex = /&[^;]+;/g

  const cleanText = inputText.replace(htmlTagsRegex, '').trim()

  return cleanText.replace(htmlEntitiesRegex, '').trim()
}

export const toKebabCase = (str) => {
  if (typeof str !== 'string') {
    console.warn(`toKebabCase expected a string, received: ${typeof str}`, str)
    return ''
  }

  const camelToKebabRegex = /([a-z])([A-Z])/g
  const whitespaceRegex = /\s+/g
  const nonWordRegex = /[^\w-]/g

  return str
    .trim()
    .replace(camelToKebabRegex, '$1-$2')
    .replace(whitespaceRegex, '-')
    .replace(nonWordRegex, '')
    .toLowerCase()
}


export const toSnakeCase = (str) => {
  if (typeof str !== 'string') {
    console.warn(`toSnakeCase expected a string, received: ${typeof str}`, str)
    return ''
  }

  const camelToSnakeRegex = /([a-z])([A-Z])/g
  const whitespaceRegex = /\s+/g
  const nonWordRegex = /[^\w_]/g

  return str
    .trim()
    .replace(camelToSnakeRegex, '$1_$2')
    .replace(whitespaceRegex, '_')
    .replace(nonWordRegex, '')
    .toLowerCase()
}

export const validateURL = (url) => {
  const protocolPattern = /^(https?:\/\/)?/
  const domainPattern = /([\w\-]+\.)+[\w\-]{2,}/
  const pathPattern = /(\/.*)?$/

  const fullPattern = new RegExp(
    protocolPattern.source + domainPattern.source + pathPattern.source,
    'i',
  )

  return fullPattern.test(url)
}

export const defaultIfEmpty = (value) => (value?.length ? value : EMPTYVALUE)

export const filterQueryParams = (currentParams, newParams) => {

  const currentParamsObj = currentParams instanceof URLSearchParams
    ? Object.fromEntries(currentParams.entries())
    : currentParams

  const mergedParams = { ...currentParamsObj, ...newParams }

  return Object.fromEntries(
    Object.entries(mergedParams).filter(([key, value]) => {
      if (key === 'page' && value == 1) return false
      if (key === 'page_size' && value == 10) return false
      if (key === 'stage' && value === 'all') return false
      return value !== '' && value !== null && value !== undefined
    }),
  )
}
